export const usePrices = (modelos) => {
  let preciosTotal = [];

  const PRECIOS = {
    contado: {
      casaPack: {
        superficieCubierta: {
          unaPlanta: 380000,
        },
        superficieSemicubierta: {
          unaPlanta: 190000,
        },
      },
      casaPro: {
        superficieCubierta: {
          unaPlanta: 380000,
        },
        superficieSemicubierta: {
          unaPlanta: 190000,
        },
      },
      casaMix: {
        superficieCubierta: {
          unaPlanta: 380000,
        },
        superficieSemicubierta: {
          unaPlanta: 190000,
        },
      },
    },
    financiado: {
      casaPack: {
        superficieCubierta: {
          unaPlanta: 380000,
        },
        superficieSemicubierta: {
          unaPlanta: 190000,
        },
      },
      casaPro: {
        superficieCubierta: {
          unaPlanta: 380000,
        },
        superficieSemicubierta: {
          unaPlanta: 190000,
        },
      },
      casaMix: {
        superficieCubierta: {
          unaPlanta: 380000,
        },
        superficieSemicubierta: {
          unaPlanta: 190000,
        },
      },
    },
  };

  modelos.forEach((modelo) => {
    let precioTotal;
    let entrega;
    let restoCuotas;
    let precioCuota;
    let precioContado;

    switch (modelo?.cantidadDePlantas) {
      case 1:
        precioTotal =
          PRECIOS.financiado[modelo?.usePrices].superficieCubierta.unaPlanta *
            modelo.superficies.superficieCubierta +
          PRECIOS.financiado[modelo?.usePrices].superficieSemicubierta
            .unaPlanta *
            modelo.superficies.superficieSemicubierta;

        precioContado =
          PRECIOS.contado[modelo?.usePrices].superficieCubierta.unaPlanta *
            modelo.superficies.superficieCubierta +
          PRECIOS.contado[modelo?.usePrices].superficieSemicubierta.unaPlanta *
            modelo.superficies.superficieSemicubierta;

        entrega = Math.round(precioTotal * modelo.porcentajeDeEntrega);

        restoCuotas = precioTotal - entrega;
        precioCuota = Math.round(restoCuotas / modelo.cantidadDeCuotas);

        preciosTotal.push([entrega, precioCuota, precioContado, precioTotal]);
        break;

      case 2:
        precioTotal =
          PRECIOS.financiado[modelo?.usePrices].superficieCubierta.dosPlanta *
            modelo.superficies.superficieCubierta +
          PRECIOS.financiado[modelo?.usePrices].superficieSemicubierta
            .dosPlanta *
            modelo.superficies.superficieSemicubierta;

        precioContado =
          PRECIOS.contado[modelo?.usePrices].superficieCubierta.dosPlanta *
            modelo.superficies.superficieCubierta +
          PRECIOS.contado[modelo?.usePrices].superficieSemicubierta.dosPlanta *
            modelo.superficies.superficieSemicubierta;

        entrega = Math.round(precioTotal * modelo.porcentajeDeEntrega);

        restoCuotas = precioTotal - entrega;
        precioCuota = Math.round(restoCuotas / modelo.cantidadDeCuotas);

        preciosTotal.push([entrega, precioCuota, precioContado, precioTotal]);
        break;

      default:
        break;
    }
  });
  return preciosTotal;
};
